import React from "react"
import styled from 'styled-components'
import Layout from '../components/layout'
import Seo from '../components/seo'
import {Promotion} from '../components/promotions/promotion'




const TorunPromocjaDlaNowychKlientow = () => {

  return (
    <Layout torun>
    <Seo
      title="Torun promocja"
      description="Torun promocja"
      url="https://bodymedgroup.pl"
    />
    
    <Promotion/>
    </Layout>
  )
}



export default TorunPromocjaDlaNowychKlientow


